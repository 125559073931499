<template>
  <pro-menu-layout>
    <pro-deck :title="title" :cards="cards">
      <template v-slot:action v-if="hasBoq">
        <pro-button
          outline
          :label="$t('System.Button.BoqSummary')"
          icon="PROSmart-download"
          @click="downloadBoq"
        />
      </template>
      <template v-slot:default="{ card }">
        <pro-deck-card expandable :title="card.title">
          <pro-folders :folders="card.folders" :show-download-button="true" />
          <div v-if="card.boq" style="margin-top: 16px">
            <span
              class="ellipsis"
              style="font-size: 17px"
              v-text="$t('Form.Col.BOQ')"
            />
            <pro-report
              row-key="id"
              :value="card.boq"
              :columns="columns"
              simple-table-mode
            ></pro-report>
          </div>
        </pro-deck-card>
      </template>
    </pro-deck>
  </pro-menu-layout>
</template>

<script>
import ProFolders from "@/components/PROSmart/ProFolders";
import ProMenuLayout from "@/components/PROSmart/Layout/ProMenuLayout";
import ProDeck from "@/components/PROSmart/Deck/ProDeck";
import ProDeckCard from "@/components/PROSmart/Deck/ProDeckCard";
import ProReport from "@/components/PROSmart/Report/ProReport.vue";
import ProButton from "@/components/PROSmart/ProButton.vue";

export default {
  components: {
    ProButton,
    ProReport,
    ProDeckCard,
    ProMenuLayout,
    ProDeck,
    ProFolders,
  },
  name: "ProViewProposals",
  props: {
    tenderers: { type: Array, default: () => [] },
    title: { type: String, default: "" },
    hasBoq: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      columns: [
        {
          name: "item",
          i18NLabel: "Form.Field.Item",
          field: "item",
          align: "left",
          searchable: true,
          sortable: true,
          type: "string",
          defaultShow: false,
        },
        {
          name: "itemCode",
          i18NLabel: "Form.Field.ItemCode",
          field: "itemCode",
          align: "left",
          searchable: true,
          sortable: true,
          type: "string",
          style: "width: 100px;",
        },
        {
          name: "description",
          i18NLabel: "Form.Field.Description",
          field: "description",
          align: "left",
          searchable: true,
          sortable: true,
          type: "string",
        },
        {
          name: "quantity",
          i18NLabel: "Form.Field.Quantity",
          field: "quantity",
          align: "left",
          searchable: true,
          sortable: true,
          type: "float",
        },
        {
          name: "unit",
          i18NLabel: "Form.Field.Unit",
          field: "unit",
          align: "left",
          searchable: true,
          sortable: true,
          type: "string",
          style: "width: 50px",
        },
        {
          name: "unitPrice",
          i18NLabel: "Form.Field.UnitPrice",
          field: "unitPrice",
          align: "left",
          searchable: true,
          sortable: true,
          type: "currency",
          prefix: (colValue, col, row) => row.currency,
        },
        {
          name: "amount",
          i18NLabel: "Form.Field.Amount",
          field: "amount",
          align: "left",
          searchable: true,
          sortable: true,
          type: "currency",
          convertTo: "currency",
          prefix: (colValue, col, row) => row.currency,
          operation: (rowList) => rowList.quantity * rowList.unitPrice,
          bottomRow: "sum",
        },
        {
          name: "remark",
          i18NLabel: "Form.Field.Remark",
          field: "remark",
          align: "left",
          searchable: true,
          sortable: true,
          type: "string",
        },
      ],
    };
  },
  computed: {
    cards: function () {
      return this.tenderers.reduce((array, { ref, name, ...rest }) => {
        return [...array, { alias: ref, title: name, ...rest }];
      }, []);
    },
  },
  methods: {
    downloadBoq() {
      let tenderId = this.$route.params.id;

      this.$proSmart.boq.getBoqSummaryExcel(this, tenderId).then((file) => {
        this.$proSmart.download.processDownloadFile(
          file.name,
          file.content,
          file.type
        );
      });
    },
  },
};
</script>
