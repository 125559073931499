<template>
  <pro-view-proposals
    :title="title"
    :tenderers="tenderers"
    :has-boq.sync="hasBoq"
  />
</template>

<script>
import ProViewProposals from "@/components/PROSmart/ProViewProposals";

export default {
  components: {
    ProViewProposals,
  },
  data() {
    return {
      title: "Commercial Folder – Tenderer Version",
      tenderers: [],
      hasBoq: false,
    };
  },
  mounted() {
    Promise.all([
      this.$proSmart.tenderView.getOpeningRecordCommercialProposals(
        this,
        this.$route.params.id
      ),
      this.$proSmart.tenderView.viewBoqProposals(this, this.$route.params.id),
    ]).then(([priceRecord, boqList]) => {
      this.hasBoq = Object.keys(boqList).length > 0;

      this.tenderers = priceRecord.map((tenderer) => {
        let data = {
          ref: tenderer.ref,
          name: tenderer.name,
          folders: tenderer.commercialFolder,
        };

        if (boqList[tenderer.tendererId]) {
          data.boq = boqList[tenderer.tendererId];
        }

        return data;
      });
    });
  },
};
</script>
